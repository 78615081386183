/** @jsx jsx */
import { jsx, Container } from 'theme-ui';
import Masonry from 'react-masonry-component';
import SectionHeading from 'components/section-heading';
import FaqItem from 'components/cards/faq-item';

const data = [
  {
    id: 1,
    ques: 'What is Redz?',
    ans: `Redz is a Augmented Reality social media application where you can create & share - Short Videos, NFTs and Metaverse 360° Media, for which you can earn Cryptocurrency based on your popularity of your content.`,
  },
  {
    id: 2,
    ques: 'Why Redz?',
    ans: `Redz is an opportunity for Creators, Influencers and Artists to go free from the Brands and Advertisements by getting a constant source of Income. Redz is the first Web 3.0 social media application with no user data collection and absolute privacy using blockchain technology.`,
  },
  {
    id: 3,
    ques: 'What is XVR?',
    ans: `XVR is an affordable Virtual Reality standalone headset which uses the cloud streaming technology to directly stream contents into the headset. This helps in removing weight and unneccessary component inside VR headset.`,
  },
  {
    id: 4,
    ques: 'What is Cloud Streaming Technology?',
    ans: `When we watch movies on Netflix, we do not store the entire content on our local hardware, say for example, Laptop or Phone. The contents are stored in a remote server and it is streamed to your device when you request it. This is called Cloud Streaming.`,
  },
  {
    id: 5,
    ques: 'What are the benefits of Direct VR Cloud Streaming?',
    ans: `Affordability is our main focus. Our vision to make Virtual Reality a mainstream consumer electronics needs affordable price for mass public without compromising the Quality.`,
  },
  {
    id: 6,
    ques: 'When to expect release XVR?',
    ans: `We are working 24*7 to bring you as soon as possible. We are currently testing out our beta release to specific user group. We'll do the public release very soon.`,
  },
  {
    id: 7,
    ques: 'Where to find more on updates?',
    ans: `Please join our Discord community for constant updates.`,
  },
];

const masonryOptions = { originTop: true };

const Faq = () => {
  return (
    <section id="faq" sx={styles.section}>
      <Container>
        <SectionHeading
          sx={{ mb: 15 }}
          slogan="Get yours question answer"
          title="Frequantly asked question"
        />
        <Masonry options={masonryOptions} sx={styles.grid}>
          {data.map((item) => {
            return <FaqItem key={item.id} faq={item} className="faq-item" />;
          })}
        </Masonry>
      </Container>
    </section>
  );
};

export default Faq;

const styles = {
  section: {
    pt: [8, null, null, null, 10, 14],
    pb: [null, null, null, null, null, 10, 14],
  },
  grid: {
    mx: [null, null, null, -6, -8, 'unset'],
  },
};
